

























































































































































































import Vue from 'vue'
import moment from 'moment'

import { IPremioModel, IRutaModel, IRutaResevaModel, ISolicitudPremioModel } from '@/store/types'

import RutaService from '@/services/RutaService'
import PremioService from '@/services/PremioService'

export default Vue.extend({
  components: {},

  data() {
    return {
      valorPunto: 5000,
      puntos: 50,
      num: '+573209963324',
      identificacion: '', //'1144049863',
      identificacionC: '', //'1144049863',
      records: [] as IPremioModel[],
      reservas: [] as IRutaResevaModel[],

      table: {
        dialog: false,
        loading: true,
        headers: [
          { text: 'Ruta', value: 'Nombre', align: 'left' },
          { text: 'Fecha', value: 'Fecha', align: 'left' },
          { text: 'Valor', value: 'Valor', align: 'left' },
          { text: 'Puntos', value: 'Puntos', align: 'left' },
          { text: 'Estado', value: 'EstadoPuntos', align: 'left' }
        ],
        records: [],
        rowsPerPage: [5, 10, 20, 25, 30, { text: 'Todos', value: -1 }]
      }
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    formatPrice(value: number, simbol = true) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      const s = simbol ? '$ ' : ''
      return (
        s +
        val
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
          .replace(',00', '')
      )
    },

    async reserve(item: IPremioModel) {
      if (this.identificacionC) {
        this.$store.dispatch('app/showLoading')
        await PremioService.saveSolicitudPremio({
          IdProducto: item.Id,
          Puntos: item.Puntos,
          Nombre: item.Nombre,
          Descripcion: item.Descripcion,
          Identificacion: this.identificacionC,
          Solicitante: this.reservas[0]?.Nombre,
          Estado: 'Pendiente'
        } as ISolicitudPremioModel)
        this.$store.dispatch('app/hideLoading')

        this.$store
          .dispatch('app/alertPrompt', {
            text: 'solicitud registrada con éxito, en breve nos comunicaremos con usted.',
            type: 'error'
          })
          .then(() => {
            var msg = `Hola ECOcaminantes, quiero redimir mis puntos:\r\r\n*Producto:* ${item.Nombre}\r\n ${item.Descripcion}\r\n*Puntos a redimir:* ${item.Puntos}\r\n\r\nIdentificación:\r\n*${this.identificacionC}*\r\nNombre solicitante:\r\n*${this.reservas[0]?.Nombre}*\r\nGracias!`
            window.open(`https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(msg)}`, '_blank')
          })
      } else {
        this.$store.dispatch('app/alertPrompt', {
          text: 'Por favor ingresar un número de identificación valido.',
          type: 'error'
        })
      }
    },

    search() {
      this.identificacionC = ''
      if (this.identificacion) {
        this.identificacionC = this.identificacion
        this.loadReservas()
      } else {
        this.$store.dispatch('app/alertPrompt', {
          text: 'Por favor ingresar un número de identificación valido.',
          type: 'error'
        })
      }
    },

    calculoPuntos() {
      let valores = 0
      this.reservas.forEach((row: IRutaResevaModel) => {
        valores += row.ValorReal
      })

      return Math.round((valores / this.valorPunto) * 50 || 0)
    },

    reservasConfirmadas(item: IRutaModel): IRutaResevaModel[] {
      if (!item.Reservas) item.Reservas = []

      let reservas = item.Reservas.filter(
        (f) => f.Estado === 'Confirmada' && f.Identificacion && f.Identificacion.replace('.', '').replace(',', '') === this.identificacion.replace('.', '').replace(',', '')
      )

      return reservas.map((r) => {
        return { ...r, RutaNombre: item.Nombre, RutaFecha: item.FechaCompleta }
      })
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      this.records = await PremioService.findAll(false)
      this.$store.dispatch('app/hideLoading')
    },

    async loadReservas() {
      this.$store.dispatch('app/showLoading')
      let records = (await RutaService.findAll(false)).filter((f) => moment(f.Fecha, 'YYYY-MM-DD').year() >= 2022)
      records = records.filter((f) => f.Estado === 'Disponible' || f.Estado === 'Agotado' || f.Estado === 'Finalizada')
      this.reservas = []
      records.forEach((row: IRutaModel) => {
        this.reservasConfirmadas(row).forEach((reseva: IRutaResevaModel) => {
          this.reservas.push({ ...reseva, Puntos: Math.round((reseva.Valor / this.valorPunto) * 50 || 0), EstadoPuntos: 'Pendiente por redimir' })
        })
      })

      this.$store.dispatch('app/hideLoading')
      if (this.reservas.length === 0) {
        this.$store.dispatch('app/alertPrompt', {
          text: 'Ups! no encontramos reservas, pero no te quedes con las ganas de vivir la experiencia.',
          type: 'info'
        })
      }
    }
  }
})
